import React, { useState } from 'react';
import styled from 'styled-components';

import { Icon } from 'components/common';
import { Button } from 'components/bootstrap';

interface Props {
  className: string;
  onToggle: (visible: boolean) => void;
  title: string;
  visible: boolean;
}

const AdditionalFields: React.FC<Props> = ({
  children,
  className,
  onToggle = () => {},
  title,
  visible = false,
}) => {
  const [fieldsVisible, setFieldsVisible] = useState<boolean>(visible);

  const handleToggle = () => {
    setFieldsVisible(!fieldsVisible);
    onToggle(!fieldsVisible);
  };

  return (
    <div className={className}>
      <ToggleAdditionalFields bsStyle="link"
                              bsSize="xsmall"
                              onClick={handleToggle}
                              type="button">
        {title}{' '}
        <Icon name={fieldsVisible ? 'angle-down' : 'angle-right'} fixedWidth />
      </ToggleAdditionalFields>

      <AdditionalFieldsContent visible={fieldsVisible}>
        {children}
      </AdditionalFieldsContent>
    </div>
  );
};

const AdditionalFieldsContent = styled.div`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  padding: 0 100px 0 25px;
`;

const ToggleAdditionalFields = styled(Button)`
  border: 0;
  display: block;
  font-size: 14px;

  :hover {
    text-decoration: underline;
  }
`;

export default AdditionalFields;
