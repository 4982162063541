import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import history from 'util/History';
import { getValueFromInput } from 'util/FormsUtils.js';
import ConfirmLeaveDialog from 'components/common/ConfirmLeaveDialog';
import Wizard from 'components/common/Wizard';
import Routes from 'routing/Routes';

import StepSubscribe from './StepSubscribe';
import StepReview from './StepReview';
import StepAuthorize from './StepAuthorize';
import { StepsContext } from './context/Steps';
import { SidebarContext } from './context/Sidebar';
import type { FormData } from './context/FormData';
import { FormDataContext } from './context/FormData';

type Props = {
  onSubmit?: (FormData) => void,
  externalInputSubmit: boolean,
};

const GCP = ({ externalInputSubmit, onSubmit }: Props) => {
  const {
    availableSteps,
    currentStep,
    isDisabledStep,
    setAvailableStep,
    setCurrentStep,
    setEnabledStep,
  } = useContext(StepsContext);
  const { setFormData } = useContext(FormDataContext);
  const { sidebar, clearSidebar } = useContext(SidebarContext);
  const [dirty, setDirty] = useState(false);
  const [lastStep, setLastStep] = useState(false);

  const wizardSteps = useMemo(() => {
    const handleFieldUpdate = ({ target }, fieldData) => {
      const id = target.name || target.id;
      let value = getValueFromInput(target);

      if (typeof value === 'string') {
        value = value.trim();
      }

      if (!dirty) {
        setDirty(true);
      }

      setFormData(id, { ...fieldData, value });
    };

    const handleSubmit = (maybeFormData?: FormData) => {
      clearSidebar();
      const nextStep = availableSteps.indexOf(currentStep) + 1;

      if (availableSteps[nextStep]) {
        const key = availableSteps[nextStep];

        setEnabledStep(key);
        setCurrentStep(key);
      } else {
        setLastStep(true);

        if (externalInputSubmit && onSubmit) {
          const formData = maybeFormData || {}; // maybeFormData should always be passed if externalInputSubmit is set.
          onSubmit(formData);
        } else {
          history.push(Routes.SYSTEM.INPUTS);
        }
      }
    };

    return [
      {
        key: 'authorize',
        title: <>Google Cloud Connection Configuration</>,
        component: (<StepAuthorize onSubmit={handleSubmit}
                                   onChange={handleFieldUpdate} />),
        disabled: isDisabledStep('authorize'),
      },
      {
        key: 'subscribe',
        title: <>Google Cloud Log Selection</>,
        component: (<StepSubscribe onSubmit={handleSubmit}
                                   onChange={handleFieldUpdate} />),
        disabled: isDisabledStep('subscribe'),
      },
      {
        key: 'review',
        title: <>Google Cloud Final Review</>,
        component: (<StepReview onSubmit={handleSubmit}
                                externalInputSubmit={externalInputSubmit} />),
        disabled: isDisabledStep('review'),
      },

    ];
  }, [availableSteps, clearSidebar, currentStep, dirty, isDisabledStep, setCurrentStep, setEnabledStep, setFormData, onSubmit, externalInputSubmit]);

  useEffect(() => {
    if (availableSteps.length === 0) {
      setAvailableStep(wizardSteps.map((step) => step.key));
    }
  }, [availableSteps, setAvailableStep, wizardSteps]);

  return (
    <>
      {dirty && !lastStep && <ConfirmLeaveDialog question="Are you sure? Your new Input will not be created." />}
      <Wizard steps={wizardSteps}
              activeStep={currentStep}
              onStepChange={setCurrentStep}
              horizontal
              justified
              hidePreviousNextButtons>
        {sidebar}
      </Wizard>
    </>
  );
};

GCP.propTypes = {
  externalInputSubmit: PropTypes.bool,
  onSubmit: PropTypes.func,
};

GCP.defaultProps = {
  externalInputSubmit: false,
  onSubmit: undefined,
};

export default GCP;
